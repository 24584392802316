export const toolbarSettings = [
    {
        name: 'trash',
        permission: 'content/publish'
    },
    {
        name: 'placement',
        permission: 'content/publish'
    },
    {
        name: 'massComments',
        permission: 'content/publish'
    },
    {
        name: 'createPageEssay',
        permission: 'content/publish'
    }
]
