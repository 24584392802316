export const filesFilters = {
    config: {
        rowsPerPage: true,
        paginationPage: true,
        status: true,
        search: true
    },
    initValues: {
        status: 'any'
    },
    lists: {
        fieldsList: [
            {
                value: 'filename',
                text: 'Filename'
            }
        ],
        statusList: [
            {
                value: 'all',
                text: 'All'
            },
            {
                value: 'trashed',
                text: 'Trashed'
            },
            {
                value: 'placed',
                text: 'Placed'
            },
            {
                value: 'unreviewed',
                text: 'Unreviewed'
            }
        ]
    }
}
