<template>
    <custom-table
        :filter_settings="filesFilters"
        :filter_items="filterItems"
        :page_filters="page_filters"
        :filters="filters"
        :headers_items="headerItems"
        :toolbar_settings="toolbarSettings"
        :loading="loading || !items"
        :content-type="contentType"
        :items="items"
        :options="tableOptions"
        @getData="getList($event)"
        @selectAllCheckbox="selectAllCheckbox($event)"
    />
</template>

<script>
import contentBaseMixin from '@/mixins/content/contentBaseMixin'
import { headerItems } from '@/helpers/constants/files/content-table-header-items'
import { FilterItems, Filters } from '@/helpers/constants/files/filters'
import { filesFilters } from '@/helpers/filters-settings/files-filters'
import { toolbarSettings } from '@/helpers/constants/files/toolbar-settings'

import { createNamespacedHelpers } from 'vuex'
import { debounce, isEqual } from 'lodash';
import { mapFilesAdditionalFields } from '@/store/modules/files-additional'
import { ACTION_GET_ADDITIONS_FILES } from '@/store/modules/files-additional/action-types'

const {
    mapActions: mapFilesActions
} = createNamespacedHelpers('filesAdditional')

export default {
    name: 'Index',
    mixins: [
        contentBaseMixin
    ],
    data() {
        return {
            tableOptions: {
                filters: true,
                content: true,
                toolbar: true,
                hiddenModals: false
            },
            filters: {},
            currentRoute: 'files-legacy-list-additions',
            filesFilters
        }
    },
    computed: {
        ...mapFilesAdditionalFields([
            'files',
            'loading'
        ]),
        headerItems() {
            return headerItems
        },
        items() {
            return this.files
        },
        filterItems() {
            return FilterItems
        },
        allToolbarSettings() {
            return toolbarSettings
        },
        page_filters() {
            return Filters
        }

    },
    watch: {
        '$route.query': debounce(function (val) {
            if (this.$route.name !== 'files-legacy-list-additions') return
            if (isEqual(this.$route.query, this.filters)) return
            this.filters = {
                ...val
            }
            this.getList(val)
        }, 50)
    },
    created() {
    },
    methods: {
        ...mapFilesActions([
            ACTION_GET_ADDITIONS_FILES
        ]),
        getList(query) {
            this[ACTION_GET_ADDITIONS_FILES]({ ...query })
        }
    }
}
</script>

<style scoped>

</style>
